<template>
	<div class="contacts-block">
		<h1 class="title">Контакты</h1>
		<div class="contacts">
			<div class="contacts-text">
				<div class="contacts-info">
					<p>Контактная информация:</p>
					<p>
						По вопросам приобретения и сотрудничества: <a href="mailto:vr@stemgames.ru" class="text-email">vr@stemgames.ru</a>
					</p>
					<p>
						Техническая поддержка: <a href="mailto:support@stemgames.ru" class="text-email">support@stemgames.ru</a>
					</p>
					<p>Наш офис находится в Москве.</p>
					<p>Если вы хотите получить бесплатную демонстрацию продукта, напишите нам.</p>
					<p>Мы на связи с 9:00 до 18:00 по московскому времени.</p>
				</div>
				<div class="contacts-info">
					<p>Данные о юридическом лице:</p>
					<p>Общество с ограниченной ответственностью «СТЕМ-игры»</p>
					<p>ОГРН 1177746476192</p>
					<p>ИНН 9705097051</p>
				</div>
			</div>
			<img src="@/assets/images/paper-airplane-logo.svg" alt="Картинка контактов" class="contacts-image" />
		</div>
	</div>
</template>

<style lang="scss" scoped>
.title {
	@extend %roboto-headline-3;
	color: $primary-dark;
	text-align: center;
	margin-bottom: 3rem;
}

.contacts {
	display: flex;
	justify-content: space-between;
	gap: 1rem;
	padding: 2rem 4rem;
	max-width: 80.562rem;
	border: 2px solid $primary-dark;
	border-radius: 1.5rem;
	margin: 0 auto;

	p {
		@extend %roboto-body-1;
		color: $text-secondary;
		margin: 0;
	}

	&-info {
		p:first-child {
			color: $text-primary;
		}
	}

	.contacts-info:first-child {
		margin-bottom: 2rem;
	}

	.contacts-image {
		width: 20.875rem;
		height: auto;
	}
}

@include media-breakpoint-down(xl) {
	.title {
		margin-bottom: 2rem;
	}

	.contacts {
		padding: 2rem;

		.contacts-text {
			.contacts-info:first-child {
				margin-bottom: 1;
			}

			.contacts-info:last-child {
				max-width: 18.125rem;
			}
		}
		.contacts-image {
			width: 13rem;
			height: auto;
		}
	}
}
@include media-breakpoint-down(md) {
	.title {
		margin-bottom: 3rem;
	}

	.contacts {
		padding: 0.75rem;

		.contacts-info:first-child {
			margin-bottom: 1rem;
		}

		.contacts-image {
			display: none;
		}
	}
}
</style>
