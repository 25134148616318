<template>
	<div class="sliders-block">
		<div v-for="(slider, sidx) in sliders" :key="`slider-${sidx}`" class="sliders-block-slider">
			<h3 class="sliders-block-slider-title">{{ slider.title }}</h3>
			<div class="sliders-block-slider-container">
				<slider v-if="slider?.cards.length > 0" :items="slider?.cards">
					<template #default="{ item, keyValue }">
						<slider-card :src="item.src" :label="item.label" :href="item.href" :key="keyValue" />
					</template>
				</slider>
			</div>
		</div>
	</div>
</template>

<script>
import Slider from '@/components/Slider.vue';
import SliderCard from '@/components/SliderCard.vue';

export default {
	computed: {
		sliders() {
			return [
				{
					title: 'Клиенты',
					cards: [
						{
							src: require('@/assets/images/clients/image-1.png'),
							label: 'Международная гимназия Сколково',
							href: 'https://gymnasium.sk.ru/',
						},
						{
							src: require('@/assets/images/clients/image-2.png'),
							label: 'Казанский федеральный университет',
							href: 'https://kpfu.ru/',
						},
						{
							src: require('@/assets/images/clients/image-3.png'),
							label: 'УрГПУ',
							href: '',
						},
						{
							src: require('@/assets/images/clients/image-4.png'),
							label: 'Витебский государственный университет имени Машерова',
							href: 'https://vsu.by/',
						},
						{
							src: require('@/assets/images/clients/image-5.png'),
							label: 'МГПУ',
							href: 'https://www.mgpu.ru/',
						},
						{
							src: require('@/assets/images/clients/image-6.png'),
							label: 'Физтех-лицей им. П.Л. Капицы',
							href: 'https://anoo.ftl.name/',
						},
						{
							src: require('@/assets/images/clients/image-7.png'),
							label: 'ГБОУ ГИМНАЗИЯ №295 ФРУНЗЕНСКОГО РАЙОНА САНКТ-ПЕТЕРБУРГА',
							href: 'http://school295.spb.ru/',
						},
						{
							src: require('@/assets/images/clients/image-8.png'),
							label: 'ГБОУ Школа имени Достоевского',
							href: 'https://shkid.mskobr.ru/',
						},
						{
							src: require('@/assets/images/clients/image-9.png'),
							label: 'Армавирский государственный педагогический университет',
							href: '',
						},
						{
							src: require('@/assets/images/clients/image-10.png'),
							label: 'МБОУ СОШ № 15, Батайск',
							href: 'https://school15btsk.ru/',
						},
						{
							src: require('@/assets/images/clients/image-11.png'),
							label: 'Политехнический лицей №182',
							href: '',
						},
						{
							src: require('@/assets/images/clients/image-12.png'),
							label: 'МОАУ СОШ №63 Оренбург',
							href: 'https://sh63-orenburg-r56.gosweb.gosuslugi.ru/',
						},
						{
							src: require('@/assets/images/clients/image-13.png'),
							label: 'ГБОУ «Школа № 1950»',
							href: 'https://sch1950.mskobr.ru/',
						},
						{
							src: require('@/assets/images/clients/image-14.png'),
							label: 'МАОУ СОШ школа № 60 города Тюмени',
							href: 'http://school60-tmn.ru/',
						},
						{
							src: require('@/assets/images/clients/image-15.png'),
							label: 'Гимназия №171 Центрального района Санкт-Петербурга',
							href: 'http://www.gym171.spb.ru/',
						},
						{
							src: require('@/assets/images/clients/image-16.png'),
							label: 'Читинский педагогический колледж',
							href: '',
						},
						{
							src: require('@/assets/images/clients/image-17.png'),
							label: 'МАОУ СОШ №45 г. Новоуральска',
							href: '',
						},
						{
							src: require('@/assets/images/clients/image-18.png'),
							label: 'МОУ СОШ ЛЕСКОЛОВСКИЙ ЦЕНТР ОБРАЗОВАНИЯ',
							href: 'http://leskl.ru/',
						},
						{
							src: require('@/assets/images/clients/image-19.png'),
							label: 'ЩЁЛКОВСКАЯ ЦЕНТРАЛЬНАЯ БИБЛИОТЕКА',
							href: 'https://bibliocentr.ru/',
						},
						{
							src: require('@/assets/images/clients/image-20.png'),
							label: 'ЦБС г. Дзержинский',
							href: 'https://stat.rgdb.ru/component/method/?view=library&Itemid=0&id=30989',
						},
						{
							src: require('@/assets/images/clients/image-21.png'),
							label: 'МБОУ СОШ №7 г. Губкинский',
							href: 'https://s7gub.yanao.ru/',
						},
						{
							src: require('@/assets/images/clients/image-22.png'),
							label: 'МАОУ СОШ №104 г. Челябинска',
							href: 'https://www.mou104.ru/',
						},
						{
							src: require('@/assets/images/clients/image-23.png'),
							label: 'СОШ №9, Сургут',
							href: 'http://school9.admsurgut.ru/',
						},
						{
							src: require('@/assets/images/clients/image-24.png'),
							label: 'МАОУ СШ №10 Г.ПАВЛОВО',
							href: 'https://s10pav.ru/',
						},
						{
							src: require('@/assets/images/clients/image-25.png'),
							label: 'НГУ им. Н.И. Лобачевского',
							href: 'http://www.unn.ru/',
						},
						{
							src: require('@/assets/images/clients/image-26.png'),
							label: 'Дзержинский химический техникум имени Красной армии',
							href: 'https://dhtdz.ru/',
						},
					],
				},
				{
					title: 'Партнеры',
					cards: [
						{
							src: require('@/assets/images/partners/image-2.png'),
							label: 'Vizzion',
							href: 'https://vizzion.ru/',
						},
						{
							src: require('@/assets/images/partners/image-3.png'),
							label: 'Edcomm',
							href: 'https://www.edcomm.ru/',
						},
						{
							src: require('@/assets/images/partners/image-4.png'),
							label: 'MediaShell',
							href: 'https://mediashell.ru/',
						},
						{
							src: require('@/assets/images/partners/image-5.png'),
							label: 'Информационные решения',
							href: 'https://ir74.ru/',
						},
						{
							src: require('@/assets/images/partners/image-6.png'),
							label: 'IT-Concept16',
							href: 'https://it-concept16.ru/',
						},
					],
				},
			];
		},
	},

	components: {
		Slider,
		SliderCard,
	},
};
</script>

<style lang="scss" scoped>
.sliders-block {
	&-slider {
		&:first-of-type {
			margin-bottom: 7rem;

			@include media-breakpoint-down(xl) {
				margin-bottom: 5rem;
			}

			@include media-breakpoint-down(md) {
				margin-bottom: 3rem;
			}
		}

		&-title {
			@extend %roboto-headline-3;
			color: $primary-dark;
			text-align: center;
			margin-bottom: 5rem;

			@include media-breakpoint-down(xl) {
				margin-bottom: 4rem;
			}

			@include media-breakpoint-down(md) {
				margin-bottom: 3rem;
			}
		}

		&-container {
			margin: 0 auto;
			max-width: 100.75rem;

			@include media-breakpoint-down(sm) {
				margin: 0 calc((-100vw + 100%) / 2);
			}

			::v-deep(.slider) {
				.slider {
					&__items {
						overflow: hidden;
						padding: 0 4.75rem;
						margin: 0 5rem;
						gap: 4rem;

						@include media-breakpoint-down(xl) {
							padding: 0 1rem;
							margin: 0 3.25rem;
							gap: 1rem;
						}

						@include media-breakpoint-down(md) {
							padding: 0 0.75rem;
							margin: 0 2.7rem;
							gap: 0.75rem;
						}
					}

					&__prev-btn,
					&__next-btn {
						width: 5rem;
						border: 2px solid $primary-dark;
						padding: 0;

						min-width: unset;

						@include media-breakpoint-down(xl) {
							width: 3.25rem;
						}

						@include media-breakpoint-down(md) {
							width: 2.7rem;
						}

						.slider-icon {
							scale: 4;
							color: $primary-dark;
							margin: 0 auto;

							@include media-breakpoint-down(xl) {
								scale: 2;
							}

							@include media-breakpoint-down(md) {
								scale: 1;
							}
						}
					}
				}
			}
		}
	}
}
</style>
