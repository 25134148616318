<template>
	<div class="video-block">
		<div class="video-block-container">
			<iframe allow="fullscreen" :src="link" frameborder="0"></iframe>
		</div>
		<div class="video-block-text">
			<p>{{ $t('text') }}</p>
			<ul v-if="list.length !== 0">
				<li v-for="(item, idx) in list" :key="`list-item-${idx}`">{{ item }}</li>
			</ul>
		</div>
	</div>
</template>

<i18n>
	{
		"ru": {
            "text": "Виртуальная реальность дает эффект присутствия, а специальная физико-химическая модель - свободу действий и право на ошибку. Включайте в занятия те эксперименты, которые необходимы, и используйте их для знакомства с новой темой, как проблемные задания или для проверки знаний. Управляйте занятием и получайте статистику выполнения в личном кабинете. Подготовиться к занятиям и узнать о лаборатории больше Вам поможет база знаний."
		},
		"en": {
            "text": "Vic’s Science Studio enables experiments as in the real chemistry lab despite:"
		}
	}
</i18n>

<script>
export default {
	computed: {
		link() {
			if (this.$i18n.locale === 'en') {
				return 'https://www.youtube.com/embed/XJI0VMFPGzc?si=cd9coJPOLJCSm9zf';
			}
			return 'https://www.youtube.com/embed/cDA_fGHKvuc?si=UKSiPs0fN-TVpq8g';
		},
		list() {
			if (this.$i18n.locale === 'ru') {
				return [];
			}
			return ['material constraints', 'safety limitations', 'students remote location',];
		},
	},
};
</script>

<style lang="scss" scoped>
.video-block {
	display: flex;
	position: relative;
	flex-direction: column;
	align-items: center;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		z-index: -1;
		transform: translateX(-50%);
		width: 100vw;
		height: 34.25rem;
		background-image: url('@/assets/images/video-background.svg');
		background-size: cover;
		background-repeat: no-repeat;

		@include media-breakpoint-down(xl) {
			top: 2.5rem;
			background-size: contain;
		}
	}

	&-container {
		position: relative;
		top: 1.56rem;
		width: 52.5rem;
		height: 33.25rem;
		margin-bottom: 7.75rem;

		@include media-breakpoint-down(xl) {
			top: 1.5rem;
			width: 34.875rem;
			height: 22rem;
		}

		@include media-breakpoint-down(lg) {
			margin-bottom: 6.75rem;
		}

		@include media-breakpoint-down(md) {
			top: 0.625rem;
			width: 15.625rem;
			height: 9.75rem;
			margin-bottom: 4rem;
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: 3.25rem;
		}

		&:before {
			content: '';
			position: absolute;
			z-index: -1;
			top: -1.65rem;
			left: calc(50% - 0.25rem);
			transform: translateX(-50%);
			width: 68.125rem;
			height: 39.625rem;
			background-image: url('@/assets/images/laptop.png');
			background-size: cover;
			background-repeat: no-repeat;

			@include media-breakpoint-down(xl) {
				top: -1.5rem;
				left: calc(50% - 0.175rem);
				width: 45rem;
				height: 26.25rem;
			}

			@include media-breakpoint-down(md) {
				top: -0.625rem;
				left: calc(50% - 0.15rem);
				width: 20rem;
				height: 11.75rem;
			}
		}

		iframe {
			width: 100%;
			height: 100%;
		}
	}

	&-text {
		@extend %roboto-body-2;
		max-width: 86.375rem;
		color: $text-secondary;
		border-image: linear-gradient(to right, rgba(0, 0, 0, 0), $primary-dark, rgba(0, 0, 0, 0)) 1 0 / 6px / 1.75rem;
		margin: 1.75rem 0;

		& > p {
			text-align: center;
		}

		& > ul {
			width: max-content;
			margin: 0 auto;
		}

		@include media-breakpoint-down(lg) {
			border-image-outset: 1.25rem;
			margin: 1.25rem 0;
		}

		@include media-breakpoint-down(sm) {
			border-image-width: 3px;
			border-image-outset: 0.5rem;
			margin: 0.5rem 0;
		}
	}
}
</style>
